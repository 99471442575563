import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./translations/en.json";
import translationzhCN from "./translations/zh-CN.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    EN: {
        translation: translationEn,
    },
    ZH_HANT: {
        translation: translationzhCN,
    },
};

const initialLang = ["/zh-hant", "/zh/"].some((lang) =>
    window.location.pathname.includes(lang),
)
    ? "ZH_HANT"
    : "EN";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: "EN",
        resources,
        lng: initialLang,
        keySeparator: ".", // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
