import React, {useContext} from 'react'
import LoadingContext from "../../context/LoadingContext";


const Loader = (props) => {
    const {loadingState} = useContext(LoadingContext)

    return (
        <>
        <div className={`loader-container ${loadingState ? 'active' : 'inactive'}`}>
            <div className="loader">
                Loading...
            </div>
        </div>
        {props.children}
        </>
    )
}

export default Loader
