import { Switch } from "react-router-dom";
import React from "react";
import PageRouter from "./PageRouter";

const Routes = () => {
    return (
        <Switch>
            <PageRouter path="/" exact="true" />
            <PageRouter path="/:alias" />
        </Switch>
    );
};

export default Routes;
