import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

const config = {
    baseURL: process.env.REACT_APP_API_URL
};

const RESTAPI = axios.create(config);

const authInterceptor = RESTAPI.interceptors.request.use(

    async config => {
        const token = await tokenRefresh();
        config.headers = {
            'X-CSRF-Token': token.data,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'lang': (config.lang) ? config.lang : 'EN'
        };

        return config;
    },
    error => {
        Promise.reject(error)
    });

export default RESTAPI;

// Prevent recursive loop when fetching token
const tokenInstance = axios.create(config);
tokenInstance.interceptors.request.eject(authInterceptor);

export const tokenRefresh = data =>
    tokenInstance.get('session/token?_format=json', { ...data });

/**
 *
 * @param {Object} data
 * @param {string} data.email
 */
export const postSubscribeForm = data =>
    RESTAPI.post('', { ...data, form: "subscribe" });

/**
 *
 * @param {Object} data
 * @param {string} data.email
 */
export const postRegisterForm = data =>
    RESTAPI.post('', { ...data, form: "register" });

/**
 *
 * @param {Object} data
 * @param {string} data.category
 * @param {[string]} data[*] - different for vendors
 */

export const postVendorForm = data =>
    RESTAPI.post('', { ...data, form: "vendor" });

