import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { loader } from "graphql.macro";
import InlineSVG from "react-inlinesvg";

import HamburgerMenu from "../shared/HamburgerMenu";
import LanguageContext from "../../context/LanguageContext";
import UserContext from "../../context/UserContext";

const QUERY = loader("../../queries/header.query.graphql");

const languages = {
    EN: "中文",
    ZH_HANT: "Eng",
};

const Header = () => {
    const { t, i18n } = useTranslation();

    const { LanguageId, setLanguageId } = useContext(LanguageContext);

    const { userInfo } = useContext(UserContext);

    const [isListOpen, setIsListOpen] = useState(false);

    const [locationPathName, setLocationPathName] = useState('')

    const location = useLocation();


    const { error, data } = useQuery(QUERY, {
        variables: {
            LanguageId,
        },
    });

    useEffect(() => {
        setLocationPathName(location.pathname)
        console.log(location.pathname)
    }, [location])

    if (error) return null;

    const toggleListState = () => {
        setIsListOpen(!isListOpen);
    };

    const handlelanguageChange = () => {
        // TODO Temp for now. change to drop down and pass lang as param
        if (LanguageId === "EN") {
            setLanguageId("ZH_HANT");
            i18n.changeLanguage("ZH_HANT");
        } else if (LanguageId === "ZH_HANT") {
            setLanguageId("EN");
            i18n.changeLanguage("EN");
        }
    };

    const checkProposalUrl = (link) => {
        return (
            link.label === "Plan Your Wedding" &&
            location.pathname.includes("/proposal")
        );
    };

    const checkDashboardUrl = () => {
        return location.pathname.includes("/dashboard");
    };


    if (data) {

        return (
            <header id="header" className={`${isListOpen ? "toggle--on" : ""} ${checkDashboardUrl() ? "header--dashboard" : ""}`}>

                {checkDashboardUrl() && <Link className="dashboard__logo" to="/">
                    <img
                        className="logo"
                        src="/logo-white.png"
                        alt="Our Big Day"
                    />
                </Link>}

                <div className="container--large">
                    <nav className="header__nav">
                        <div className="header__links">

                            <Link className="logo__link" to="/">
                                <img
                                    className="logo"
                                    src="/logo.png"
                                    alt="Our Big Day"
                                />
                            </Link>
                            <ul
                                className={`header__list ${
                                    isListOpen ? "toggle--on" : ""
                                }`}
                            >
                                <li className="header__list--homelink">
                                    <NavLink
                                        to="/"
                                        onClick={() => setIsListOpen(false)}
                                        exact={true}
                                    >
                                        {t("header.home")}
                                    </NavLink>
                                </li>
                                {data.menuByName.links.map((link, index) => {
                                    return (
                                        <li key={index}>
                                            <NavLink
                                                to={link.url.path}
                                                onClick={() =>
                                                    setIsListOpen(false)
                                                }
                                                isActive={() =>
                                                    checkProposalUrl(link)
                                                }
                                            >
                                                {link.label}
                                            </NavLink>
                                        </li>
                                    );
                                })}
                                <li className="header__list--signin">
                                    {userInfo &&
                                    userInfo.currentUserContext &&
                                    userInfo.currentUserContext.uid > 0 ? (
                                        <a
                                            className={`btn btn--secondary header__dashboard ${locationPathName.includes("dashboard") ? 'active' : 'inactive'}`}
                                            href="/dashboard"
                                        >
                                            <InlineSVG src="/images/icons/dashboard.svg" />{" "}
                                            {t("dashboard.dashboard")}
                                        </a>
                                    ) : (
                                        <Link
                                            className="btn btn--secondary header__signin"
                                            onClick={() => setIsListOpen(false)}
                                            to="account"
                                        >
                                            {t("header.signin")}
                                        </Link>

                                    )}
                                </li>
                            </ul>
                        </div>

                        <ul className="header__settings">
                            <li className="header__settings--signin">
                                {userInfo &&
                                userInfo.currentUserContext &&
                                userInfo.currentUserContext.uid > 0 ? (
                                    <a
                                    className={`btn btn--secondary header__dashboard ${locationPathName.includes("dashboard") ? 'active' : 'inactive'}`}
                                        href="/dashboard"
                                    >
                                        <InlineSVG src="/images/icons/dashboard.svg" />{" "}
                                        {t("dashboard.dashboard")}
                                    </a>
                                ) : (
                                    <Link
                                        className="btn btn--secondary header__signin"
                                        to="account"
                                    >
                                        {t("header.signin")}
                                    </Link>
                                )}
                            </li>
                            <li>
                                <button
                                    className="btn btn--secondary no-border change-language"
                                    onClick={handlelanguageChange}
                                >
                                    {languages[LanguageId]}
                                </button>
                            </li>
                            <li>
                                <HamburgerMenu
                                    onClick={toggleListState}
                                    isListOpen={isListOpen}
                                />
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }
    return null;
};

export default Header;
