import React from "react";

/**
 *
 * @param {boolean} isListOpen
 */

const HamburgerMenu = ({ onClick, isListOpen }) => {
    return (
        <div
            className={`hamburger__container ${
                isListOpen ? "hamburger-toggle" : ""
            }`}
            onClick={onClick}
        >
            <div className={"bar1"}></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
        </div>
    );
};

export default HamburgerMenu;
