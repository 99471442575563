import React, { useState } from "react";
import "./css/App.scss";
import ApolloClient from "apollo-client";
import { loader } from "graphql.macro";

import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { ToastProvider } from "react-toast-notifications";
import { useQuery } from "@apollo/react-hooks";

import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter } from "react-router-dom";
import Analytics from "react-router-ga";
import { InMemoryCache } from "apollo-cache-inmemory";

import Routes from "./components/routes/Routes";
import Header from "./components/global/Header";
import Footer from "./components/global/Footer";
import LanguageContext from "./context/LanguageContext";
import UserContext from "./context/UserContext";
import LoadingContext from "./context/LoadingContext";

import { tokenRefresh } from "./utils/api";
import Loader from "./components/shared/Loader";

const USER_QUERY = loader("./queries/user.query.graphql");

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL + "/graphql",
    credentials: "include",
});

const authLink = setContext((_, { headers }) => {
    let token = "";
    return tokenRefresh()
        .then((res) => {
            token = res.data;

            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...headers,
                    "X-CSRF-Token": token ? token : null,
                },
            };
        })
        .catch((err) => console.log(err));
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
});

const App = () => {
    const [LanguageId, setLanguageId] = useState(() => {
        return ["/zh-hant", "/zh/"].some((lang) =>
            window.location.pathname.includes(lang),
        )
            ? "ZH_HANT"
            : "EN";
    });

    const [userInfo, setUserInfo] = useState({});

    const [loadingState, setLoadingState] = useState(false)

    useQuery(USER_QUERY, {
        client,
        onCompleted: setUserInfo,
    });

    return (
        <ApolloProvider client={client}>
            <BrowserRouter>
                <Analytics id={process.env.REACT_APP_GA_ID}>
                    <LanguageContext.Provider
                        value={{ LanguageId, setLanguageId }}
                    >
                        <UserContext.Provider value={{ userInfo, setUserInfo }}>
                            <LoadingContext.Provider value={{loadingState, setLoadingState}}>
                                <Loader>
                                    <ToastProvider
                                        autoDismiss={3000}
                                        autoDismissTimeout={3000}
                                        placement="bottom-center"
                                    >
                                        <Header />
                                        <Routes />
                                        <Footer />
                                    </ToastProvider>
                                </Loader>
                            </LoadingContext.Provider>
                        </UserContext.Provider>
                    </LanguageContext.Provider>
                </Analytics>
            </BrowserRouter>
        </ApolloProvider>
    );
};

export default App;
