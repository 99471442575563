const checkForRedirect = path => {
    const checkPath = redirectPaths[path] || redirectPaths[`${path}/`];
    if (checkPath) {
        return checkPath;
    } else if (path.includes("/all_vendors")) {
        return "/vendors";
    }
    return false;
};

const redirectPaths = {
    "/7-wedding-photographers-you-need-to-know/":
        "/blog/7-wedding-photographers-you-need-know-2019",
    "/8-best-chinese-wedding-door-games/":
        "/blog/8-best-chinese-wedding-door-games",
    "/8-reasons-wedding-kowloon-shangri-la/":
        "/blog/8-reasons-plan-your-wedding-kowloon-shangri-la-hong-kong",
    "/zh/8個有趣的接新娘遊戲指南/":
        "/zh-hant/blog/8%E5%80%8B%E6%9C%89%E8%B6%A3%E7%9A%84%E6%8E%A5%E6%96%B0%E5%A8%98%E9%81%8A%E6%88%B2%E6%8C%87%E5%8D%97",
    "/best-bridal-makeup-artists-in-hong-kong-for-2019/":
        "/blog/best-bridal-makeup-artists-hong-kong-2019",
    "/best-wedding-photographers-in-hong-kong-for-2019/":
        "/blog/best-wedding-photographers-hong-kong-2019",
    "/everything-you-need-to-know-about-guo-da-li/":
        "/blog/everything-you-need-know-about-guo-da-li",
    "/fine-art-books-why-you-will-love-them/":
        "/blog/fine-art-books-why-you-will-love-them",
    "/guides-and-inspiration/": "/blog",
    "/zh/zh-hk/": "/",
    "/hong-kong-best-luxury-wedding-venues/":
        "/blog/hong-kongs-best-luxury-wedding-venues-2019",
    "/how-it-works/": "/plan-wedding",
    "/how-to-budget-for-your-wedding/": "/blog/how-budget-your-wedding",
    "/how-to-perform-the-hair-combing-ceremony/":
        "/blog/how-perform-hair-combing-ceremony",
    "/how-to-perform-the-tea-ceremony-for-your-wedding/":
        "/blog/how-perform-tea-ceremony-your-wedding",
    "/how-to-pick-an-auspicious-date-for-your-wedding/":
        "/blog/how-pick-auspicious-date-your-wedding",
    "/how-to-prepare-your-wedding-invitations/":
        "/blog/how-prepare-your-wedding-invitations",
    "/how-to-propose-to-your-future-wife/":
        "/blog/how-propose-your-future-wife",
    "/plan-my-wedding/": "/plan-wedding",
    "/run-down-of-the-wedding-day-blog/": "/blog/run-down-wedding-day",
    "/the-best-wedding-cake-bakeries-in-hong-kong-for-2019/":
        "/blog/best-wedding-cake-bakeries-hong-kong-2019",
    "/the-best-wedding-dress-ateliers-in-hong-kong-for-2019/":
        "/blog/best-wedding-dress-ateliers-hong-kong-2019",
    "/the-best-wedding-florists-in-hong-kong-for-2019/":
        "/blog/best-wedding-florists-hong-kong-2019",
    "/the-couples-checklist-for-guo-da-li/":
        "/blog/couples-checklist-guo-da-li",
    "/the-ultimate-guide-to-choosing-a-qun-kwa-for-your-chinese-wedding/":
        "/blog/ultimate-guide-choosing-qun-kwa-your-chinese-wedding",
    "/what-to-do-as-bridesmaid-at-a-traditional-chinese-wedding/":
        "/blog/what-do-bridesmaid-traditional-chinese-wedding",
    "/what-to-do-as-groomsman-at-a-traditional-chinese-wedding/":
        "/blog/what-do-groomsman-traditional-chinese-wedding",
    "/zh/中式婚禮上頭儀式大全/":
        "/zh-hant/blog/%E4%B8%AD%E5%BC%8F%E5%A9%9A%E7%A6%AE%E4%B8%8A%E9%A0%AD%E5%84%80%E5%BC%8F%E5%A4%A7%E5%85%A8",
    "/zh/中式婚禮伴娘要準備的事情/":
        "/zh-hant/blog/%E4%B8%AD%E5%BC%8F%E5%A9%9A%E7%A6%AE%E4%BC%B4%E5%A8%98%E8%A6%81%E6%BA%96%E5%82%99%E7%9A%84%E4%BA%8B%E6%83%85",
    "/zh/中式婚禮敬茶大全/":
        "/zh-hant/blog/%E4%B8%AD%E5%BC%8F%E5%A9%9A%E7%A6%AE%E6%95%AC%E8%8C%B6%E5%A4%A7%E5%85%A8",
    "/zh/中式婚禮新郎兄弟團手冊/":
        "/zh-hant/blog/%E4%B8%AD%E5%BC%8F%E5%A9%9A%E7%A6%AE%E6%96%B0%E9%83%8E%E5%85%84%E5%BC%9F%E5%9C%98%E6%89%8B%E5%86%8A",
    "/zh/中式婚禮過大禮大全/":
        "/zh-hant/blog/%E4%B8%AD%E5%BC%8F%E5%A9%9A%E7%A6%AE%E9%81%8E%E5%A4%A7%E7%A6%AE%E5%A4%A7%E5%85%A8",
    "/zh/中式裙褂挑選指南/":
        "/zh-hant/blog/%E4%B8%AD%E5%BC%8F%E8%A3%99%E8%A4%82%E6%8C%91%E9%81%B8%E6%8C%87%E5%8D%97",
    "/zh/中式請柬/": "/zh-hant/blog/%E4%B8%AD%E5%BC%8F%E8%AB%8B%E6%9F%AC",
    "/zh/什麼是fine-art-books/":
        "/zh-hant/blog/%E4%BB%80%E9%BA%BC%E6%98%AFfine-art-books",
    "/zh/你需要知道的7位婚禮攝影師/":
        "/zh-hant/blog/%E4%BD%A0%E9%9C%80%E8%A6%81%E7%9F%A5%E9%81%93%E7%9A%847%E4%BD%8D%E5%A9%9A%E7%A6%AE%E6%94%9D%E5%BD%B1%E5%B8%AB",
    "/zh/10位值得關注的ig婚禮攝影師/":
        "/zh-hant/blog/%E5%80%BC%E5%BE%97%E5%9C%A8IG%E4%B8%8A%E9%97%9C%E6%B3%A8%E7%9A%8410%E4%BD%8D%E5%A9%9A%E7%A6%AE%E6%94%9D%E5%BD%B1%E5%B8%AB",
    "/zh/在香港九龍香格里拉大酒店舉行婚禮的八個理由/":
        "/zh-hant/blog/%E5%9C%A8%E9%A6%99%E6%B8%AF%E4%B9%9D%E9%BE%8D%E9%A6%99%E6%A0%BC%E9%87%8C%E6%8B%89%E5%A4%A7%E9%85%92%E5%BA%97%E8%88%89%E8%A1%8C%E5%A9%9A%E7%A6%AE%E7%9A%84%E5%85%AB%E5%80%8B%E7%90%86%E7%94%B1",
    "/zh/如何為您的婚禮預算/":
        "/zh-hant/blog/%E5%A6%82%E4%BD%95%E7%82%BA%E6%82%A8%E7%9A%84%E5%A9%9A%E7%A6%AE%E9%A0%90%E7%AE%97",
    "/zh/婚禮指南和靈感/": "/zh-hant/blog",
    "/zh/婚禮當日流程/":
        "/zh-hant/blog/%E5%A9%9A%E7%A6%AE%E7%95%B6%E6%97%A5%E6%B5%81%E7%A8%8B",
    "/zh/婚禮計畫/": "/zh-hant/%E5%A9%9A%E7%A6%AE%E8%A8%88%E5%8A%83",
    "/zh/怎麼向您的未來伴侶求婚/":
        "/zh-hant/blog/%E6%80%8E%E9%BA%BC%E5%90%91%E6%82%A8%E7%9A%84%E6%9C%AA%E4%BE%86%E4%BC%B4%E4%BE%B6%E6%B1%82%E5%A9%9A",
    "/zh/結婚擇吉日攻略/":
        "/zh-hant/blog/%E7%B5%90%E5%A9%9A%E6%93%87%E5%90%89%E6%97%A5%E6%94%BB%E7%95%A5",
    "/zh/過大禮用品清單/":
        "/zh-hant/blog/%E9%81%8E%E5%A4%A7%E7%A6%AE%E7%94%A8%E5%93%81%E6%B8%85%E5%96%AE",
    "/zh/關於我們/": "/zh-hant/blog",
    "/zh/香港最佳豪華婚禮宴會場地/":
        "/zh-hant/blog/2019%E9%A6%99%E6%B8%AF%E6%9C%80%E4%BD%B3%E8%B1%AA%E8%8F%AF%E5%A9%9A%E7%A6%AE%E5%AE%B4%E6%9C%83%E5%A0%B4%E5%9C%B0",
    "/all_vendors/*": "/vendors",
};

export default checkForRedirect;
