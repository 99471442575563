import React from "react";
import { Link } from "react-router-dom";

/**
 *
 * @param {*} btn
 * @param {string} btn.url
 * @param {string} btn.text
 * @param {*} btnClasses
 */

const LinkDefault = ({ btn, btnClasses, onClick }) => {
    // const goToUrl = url => {
    //     window.location = `${url}`;
    // };
    if (btn.url.includes("://") || btn.url.includes("http")) {
        return (
            <a
                className={`${btnClasses}`}
                // onClick={() => goToUrl(btn.url)}
                href={btn.url}
            >
                {btn.text}
            </a>
        );
    } else if (btn.url === "internal:/") {
        return (
            <Link className={`${btnClasses}`} to="/" onClick={onClick}>
                {btn.text}
            </Link>
        );
    }
    return (
        <Link className={`${btnClasses}`} to={btn.url} onClick={onClick}>
            {btn.text}
        </Link>
    );
};

export default LinkDefault;
