import React, { useContext } from "react";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/react-hooks";
import Link from "../shared/LinkDefault";
import { NavLink } from "react-router-dom";
import InlineSVG from "react-inlinesvg";
import LanguageContext from "../../context/LanguageContext";

const QUERY = loader("../../queries/footer.query.graphql");

const Footer = () => {
    const { LanguageId } = useContext(LanguageContext);
    const { data } = useQuery(QUERY, {
        variables: {
            LanguageId,
        },
    });

    if (data) {
        return (
            <footer id="footer">
                <div className="container">
                    <img src="/logo-footer.png" alt="Our Big Day" />
                    <ul className="footer__links">
                        {data.footer.links.map((link, index) => (
                            <li key={index}>
                                <Link
                                    btn={{
                                        url: link.url.path,
                                        text: link.label,
                                    }}
                                />
                            </li>
                        ))}
                    </ul>
                    <ul className="footer__social-media-icons">
                        <li>
                            {" "}
                            <a
                                alt="Facebook"
                                href="https://www.facebook.com/ourbigdayhk/"
                            >
                                <InlineSVG src="/images/icons/facebook.svg" />
                            </a>
                        </li>
                        <li>
                            {" "}
                            <a
                                alt="Instagram"
                                href="https://www.instagram.com/ourbigdayhk/"
                            >
                                <InlineSVG src="/images/icons/instagram.svg" />
                            </a>
                        </li>
                        <li>
                            <a
                                href="mailto:info@ourbigday.hk?subject=Enquiry"
                                rel="noopener noreferrer"
                            >
                                <InlineSVG src="/images/icons/mail.svg" />
                            </a>
                        </li>
                    </ul>

                    <ul className="footer__legal">
                        {data.legal.links.map((link, index) => (
                            <li key={index}>
                                <Link
                                    btn={{
                                        url: link.url.path,
                                        text: link.label,
                                    }}
                                />
                            </li>
                        ))}

                        <li>
                            <NavLink to="">
                                All rights reserved, 2019, Our Big Day
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </footer>
        );
    }
    return null;
};

export default Footer;
