import React, { useEffect, Suspense, lazy } from "react";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../App";
import { loader } from "graphql.macro";
import { Helmet } from "react-helmet";

import checkForRedirect from "../../utils/checkForRedirect";

// code split all routes
const ProposalLandingPage = lazy(() => import("../pages/ProposalLandingPage"));
const ProposalFormPage = lazy(() => import("../pages/ProposalFormPage"));
const ProposalFinalQuestionsPage = lazy(() => import("../pages/ProposalFinalQuestionsPage"));
const ProposalRecipientPage = lazy(() => import("../pages/ProposalRecipientPage"));
const ProposalThankyouPage = lazy(() => import("../pages/ProposalThankyouPage"));

const AccountPage  = lazy(() => import("../pages/AccountPage"));
const DashboardProposal = lazy(() => import("../pages/DashboardProposal"));
const DashboardWeddingSitePage = lazy(() => import("../pages/DashboardWeddingSitePage"));
const DashboardPage = lazy(() => import("../pages/DashboardPage"));
const DashboardFeedbackPage = lazy(() => import("../pages/DashboardFeedbackPage"));

const HomePage = lazy(() => import("../pages/HomePage"));
const PlanPage = lazy(() => import("../pages/PlanPage"));
const SharePage = lazy(() => import("../pages/SharePage"));
const GuidesPage = lazy(() => import("../pages/GuidesPage"));
const ArticlePage = lazy(() => import("../pages/ArticlePage"));
const SpecialistsPage = lazy(() => import("../pages/SpecialistsPage"));
const VendorPage = lazy(() => import("../pages/VendorPage"));
const FinanceOptions = lazy(() => import("../pages/FinanceOptionsPage"));
const CustomPage = lazy(() => import("../pages/CustomPage"));
const EmbeddedPage = lazy(() => import("../pages/EmbeddedPage"));
const ContactFormConfirmation = lazy(() => import("../pages/ContactFormConfirmation"));
const VendorGallery = lazy(() => import("../pages/VendorGallery"));
const ErrorPage = lazy(() => import("../pages/ErrorPage"));
const BlogList = lazy(() => import("../pages/BlogListPage"));

const ROUTE_QUERY = loader("../../queries/page-router.query.graphql");

const isGalleryPage = route => {
    const splitRoute = route.location.pathname.toString().split("/");

    const isTranslated = splitRoute[1] === "zh-hant";
    const checkEvent = isTranslated ? 0 : 1;
    const isEvent = splitRoute[checkEvent] === "event";

    const [startIndex, endIndex] = isTranslated ? [1, 4] : [0, 3];

    return [
        splitRoute[splitRoute.length - 1] === "gallery",
        isEvent
            ? route.location.pathname
            : splitRoute.slice(startIndex, endIndex).join("/"),
    ];
};

const isBlogList = route => {
    return route.location.pathname === "/blog/list";
};

export default props => {
    // set to path to prevent rerendering if update props

    let path = props.location.pathname;
    const redirectPath = checkForRedirect(path);

    const [isGallery, pathname] = isGalleryPage(props);
    // Hardcode homepage since it returns null sometimes...
    // TODO: EXTRACT ALL THIS.
    if (
        path === "/" ||
        path === "/zh-hant" ||
        path === "/zh-hant/" ||
        path === "/zh/zh-hk" ||
        path === "/zh/zh-hk/"
    ) {
        return (
            <div className="router--main">
                <Suspense fallback={<div></div>}>
                    <HomePage nodeId="3" bundle="homepage" />
                </Suspense>
            </div>
        );
    }

    // Gallery and blog list don't come from BE. So check for those 2 first...
    if (isGallery) {
        path = pathname;
    } else if (isBlogList(props)) {
        return (
            <div className="router--main">
                <Suspense fallback={<div></div>}>
                    <BlogList />
                </Suspense>
            </div>
        );
    } else if (redirectPath) {
        path = redirectPath;
    }
    const nodeTypes = {
        home_page: HomePage,
        plan_your_wedding: PlanPage,
        share_your_wedding: SharePage,
        guides_and_inspiration: GuidesPage,
        article: ArticlePage,
        specialists: SpecialistsPage,
        vendor: VendorPage,
        finance_options: FinanceOptions,
        contact_form_confirmation: ContactFormConfirmation,
        embedded_page: EmbeddedPage,
        custom_page: CustomPage,
    };

    const staticPages = {
        '/account': AccountPage,
        '/dashboard': DashboardPage,
        '/dashboard/proposal': DashboardProposal,
        '/dashboard/feedback': DashboardFeedbackPage,
        '/dashboard/wedding': DashboardWeddingSitePage,
        '/proposal': ProposalLandingPage,
        '/proposal/interests': ProposalFormPage,
        '/proposal/final-questions': ProposalFinalQuestionsPage,
        '/proposal/recipient': ProposalRecipientPage,
        '/proposal/thankyou' : ProposalThankyouPage
    };


    let Component = false;

    const { loading, error, data } = useQuery(ROUTE_QUERY, {
        client,
        fetchPolicy: "no-cache",
        variables: {
            path,
        },
    });

    useEffect(() => {
        if (!loading && data.route !== null) {
            // Update site title on new page
            if(data){
                const titleTag = data.route.nodeContext.entityMetatags.find(
                    tag => tag.key === "title",
                );

                document.title = titleTag.value;
            }

        }
    }, [data, loading]);
    // Render Gallery Component if user is trying to go to gallery
    if (!loading && !error && data.route !== null) {
        Component = isGallery
            ? VendorGallery
            : nodeTypes[data.route.nodeContext.entityBundle];
    } else if (!loading && staticPages[path] === undefined) {
        // only if loading is finished, or will flash the error page
        return (
            <Suspense fallback={<div></div>}>
                <ErrorPage />
            </Suspense>
        );
    }

    // Static pages
    if(staticPages[path]){
        Component = staticPages[path];
    }

    if (Component) {
        return (
            <div className="router--main">
                <Helmet>
                    {data && data.route && data.route.nodeContext.entityMetatags.map((tag, index) => (
                        <meta name={tag.key} content={tag.value} key={index} />
                    ))}
                </Helmet>{" "}
                <Suspense fallback={<div></div>}>
                    <Component
                        nodeId={data && data.route && data.route.nodeContext.entityId}
                        bundle={data && data.route && data.route.nodeContext.entityBundle}
                    />
                </Suspense>
            </div>
        );
    }

    return null;
};
